


















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import validation, { Ruler } from '@/common/element-val'
import { ElForm } from 'element-ui/types/form'
import { AlertTypeInfo, PriceAlert } from '@/services/data'
import symbolService from '@/services/symbolService'
import utility from '@/common/utility'

interface RowRuler {
  LowPrice: Ruler;
  HighPrice: Ruler;
}

export interface PriceAlertGroup {
  Low: PriceAlert;
  High: PriceAlert;
  TriggerToDo: boolean;
}

@Component
export default class SetAlertPrice extends Vue {
  name = 'SetAlertPrice.vue'
  $refs: {
    theForm: ElForm;
  }

  @Prop() visible: boolean
  @Prop() symbolName: string
  @Prop() lastPrice: number
  @Prop() change: number
  @Prop() changeRate: number
  @Prop() typeInfo: AlertTypeInfo

  innerVisible = false
  model = {
    Alerts: new Array<{ Group: PriceAlertGroup; RowRuler: RowRuler }>()
  }

  createRowRuler (alertRow: PriceAlertGroup): RowRuler {
    return {
      LowPrice: [{
        validator: validation.number(),
        trigger: 'blur'
      }, {
        validator: validation.fun()(() => {
          if (!alertRow.High.AlertPrice || !alertRow.Low.AlertPrice) return true
          else return alertRow.Low.AlertPrice && !isNaN(alertRow.Low.AlertPrice) && alertRow.Low.AlertPrice < this.lastPrice
        }, 'Target < Current Price'),
        trigger: 'blur'
      }],
      HighPrice: [{
        validator: validation.number(),
        trigger: 'blur'
      }, {
        validator: validation.fun()(() => {
          if (!alertRow.High.AlertPrice || !alertRow.Low.AlertPrice) return true
          else return alertRow.High.AlertPrice && !isNaN(alertRow.High.AlertPrice) && alertRow.High.AlertPrice > this.lastPrice
        }, 'Target > Current Price'),
        trigger: 'blur'
      }]
    }
  }

  async mounted () {
    this.innerVisible = this.visible
    await this.loadData()
  }

  @Watch('visible')
  onValueChanged (v: boolean) {
    this.innerVisible = v
  }

  @Watch('innerVisible')
  onInputValueChanged (v: boolean
  ) {
    this.$emit('update:visible', v)
  }

  async loadData () {
    const res = await symbolService.getAlertPricesAsync(this.typeInfo)
    if (res.Result) {
      const rows: PriceAlertGroup[] = []
      res.Result.forEach(p => {
        if (p.Type === 'Low') {
          const find = rows.find(q => q.Low == null)
          if (find) {
            find.Low = p
          } else {
            rows.push({ Low: p, High: null, TriggerToDo: p.TriggerToDo })
          }
        } else if (p.Type === 'High') {
          const find = rows.find(q => q.High == null)
          if (find) {
            find.High = p
          } else {
            rows.push({ High: p, Low: null, TriggerToDo: p.TriggerToDo })
          }
        }
      })
      rows.forEach(p => {
        p.Low = p.Low ?? { PriceAlertId: 0, AlertPrice: null, Type: 'Low', TriggerToDo: false }
        p.High = p.High ?? { PriceAlertId: 0, AlertPrice: null, Type: 'High', TriggerToDo: false }
        p.TriggerToDo = p.Low.TriggerToDo || p.High.TriggerToDo
      })
      this.model.Alerts = rows.map(p => {
        return { Group: p, RowRuler: this.createRowRuler(p), TriggerToDo: p.Low?.TriggerToDo || p.High?.TriggerToDo }
      })
      if (res.Result.length === 0) {
        this.addNewRow()
      }
    }
  }

  addNewRow () {
    const newAlert: PriceAlertGroup = {
      Low: { PriceAlertId: 0, AlertPrice: null, Type: 'Low', TriggerToDo: false },
      High: { PriceAlertId: 0, AlertPrice: null, Type: 'High', TriggerToDo: false },
      TriggerToDo: false
    }
    this.model.Alerts.push({
      Group: newAlert, RowRuler: this.createRowRuler(newAlert)
    })
  }

  removeRow (item: any) {
    utility.removeArrayItem(this.model.Alerts, item)
  }

  submit () {
    this.$refs.theForm.validate(async (valid: boolean) => {
      if (valid) {
        const rows: PriceAlert[] = []
        this.model.Alerts.forEach(p => {
          if (p.Group.Low.AlertPrice) {
            p.Group.Low.TriggerToDo = p.Group.TriggerToDo
            rows.push(p.Group.Low)
          }
          if (p.Group.High.AlertPrice) {
            p.Group.High.TriggerToDo = p.Group.TriggerToDo
            rows.push(p.Group.High)
          }
        })
        const res = await symbolService.addOrUpdateAlertPriceAsync(this.typeInfo, rows)
        if (res.Error) {
          await this.$alert(res.Error)
        } else {
          this.$emit('updated', { alertInfo: this.typeInfo, count: rows.length })
          this.innerVisible = false
        }
      }
    })
  }
}
